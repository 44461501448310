.tab-overflow {
	position: relative;
	overflow: hidden;
	
	@include border-radius($border-radius-sm $border-radius-sm 0 0);
	@include flex(1);
	
	& .nav {
		&.nav-tabs {
			white-space: nowrap;
			display: table;
			margin: 0 0 -1px;
			
			@include border-radius($border-radius $border-radius 0 0);
			
			& > .nav-item {
				display: table-cell;
				float: none;
				
				& > .nav-link {
					background: none;
					
					@include box-shadow(none);
				}
				& + .nav-item {
					@if $enable-rtl {
						padding-right: 0;
					} @else {
						padding-left: 0;
					}
				}
				& + .prev-button,
				& + .next-button {
					@if $enable-rtl {
						padding-right: 0;
					} @else {
						padding-left: 0;
					}
				}
			}
			& .prev-button {
				position: absolute;
				left: 0;
				top: 0;
				z-index: 10;
				display: none !important;
				opacity: 0;
				padding: 0;
		
				@include box-shadow(0 0 6px rgba($dark, .25));
				@include transition(all .2s linear);
				@if $enable-rtl {
					left: auto;
					right: 0;
				}
				
				& > .nav-link {
					margin: 0;
					background: $white;
					
					@if($enable-rtl) {
						@include border-radius(0 $border-radius 0 0);
					} @else {
						@include border-radius($border-radius 0 0);
					}
					@include box-shadow(inset 0 0 0 1px $border-color-transparent);
				}
				& + .nav-item {
					padding: 0;
				}
			}
			& .next-button {
				position: absolute;
				right: 0;
				top: 0;
				z-index: 10;
				display: none !important;
				opacity: 0;
				padding: 0;
		
				@include box-shadow(0 0 6px rgba($dark, .25));
				@include transition(all .2s linear);
				@include border-top-left-radius(0);
				@if $enable-rtl {
					right: auto;
					left: 0;
				}
				
				& > .nav-link {
					margin: 0;
					background: $white;
					
					@if($enable-rtl) {
						@include border-radius($border-radius 0 0 0);
					} @else {
						@include border-radius(0 $border-radius 0 0);
					}
					@include box-shadow(inset 0 0 0 1px $border-color-transparent);
				}
				& + .nav-item {
					padding: 0;
				}
			}
			
			&.nav-tabs-inverse {
				& > .nav-item {
					& > .nav-link {
						background: none;
						
						&:hover,
						&:focus {
							color: $white;
						}
						&.active {
							color: $primary;
							
							@include box-shadow(none);
						}
					}
				}
				& .next-button, 
				& .prev-button {
					@include box-shadow(0 0 6px $black);
					
					& + li {
						& > a {
							&.active {
								@if($enable-rtl) {
									@include border-radius(0 0 0 $border-radius);
								} @else {
									@include border-radius(0 $border-radius 0 0);
								}
							}
						}
					}
					& > .nav-link {
						background: $dark;
						
						@include box-shadow(none);
					}
				}
			}
		}
	}
	
	&.overflow-right {
		& .nav {
			&.nav-tabs{
				& .next-button {
					display: block !important;
					opacity: 1;
					background: none;
				}
			}
		}
	}
	&.overflow-left {
		& .nav {
			&.nav-tabs{
				& .prev-button {
					display: block !important;
					opacity: 1;
					background: none;
				}
			}
		}
	}
}
.flat-black .tab-overflow .next-button a,
.flat-black .tab-overflow .prev-button a {
	background: #d2d2d2;
}