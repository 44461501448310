.hljs-wrapper {
	background: $light;
	position: relative;
	
	@include border-radius($border-radius);
	@include box-shadow(inset 0 0 0 1px $border-color-transparent);
	
	& pre {
		border: none;
		padding: 0;
		background: none;
		
		@include border-radius(0);
		
		& .hljs,
		&.hljs {
			padding: rem(15px);
			display: block;
			overflow-x: auto;
			border: none;
			font-size: rem(12px);
			background: none;
			color: $dark;
			
			@include border-radius(0);
			
			& .hljs-tag,
			& .hljs-section,
			& .hljs-name,
			& .hljs-selector-tag,
			& .hljs-deletion,
			& .hljs-subst {
				color: darken($blue, 15%);
			}
			& .hljs-attr,
			& .hljs-variable,
			& .hljs-template-variable,
			& .hljs-type,
			& .hljs-selector-class,
			& .hljs-selector-attr,
			& .hljs-selector-pseudo,
			& .hljs-number {
				color: lighten($blue, 5%);
			}
			& .hljs-string,
			& .hljs-regexp,
			& .hljs-addition,
			& .hljs-attribute,
			& .hljs-meta-string {
				color: darken($red, 7.5%);
			}
			& .hljs-comment {
				color: lighten($dark, 40%);
			}
		}
	}
	& .btn-clipboard {
		position: absolute;
		right: rem(10px);
		top: rem(10px);
		
		@if $enable-rtl {
			right: auto;
			left: rem(10px);
		}
	}
}
.panel-body + .hljs-wrapper {
	@include border-radius(0 0 $border-radius $border-radius);
}
.modal {
	& .hljs-wrapper {
		margin-top: rem(-1px);
	
		@include border-radius(0);
		
		& + .modal-footer {
			border-top: none;
		}
	}
}