.media-list {
	padding-left: 0;
	padding-right: 0;
	list-style: none;
	
	&.media-list-with-divider {
		& .media {
			& + .media {
				border-top: 1px solid $border-color;
				padding-top: rem(15px);
			}
		}
	}
}

.dropdown-menu {
	&.media-list {
		max-width: rem(360px);
		padding: 0;
		
		& .media {
			border-top: 1px solid $border-color;
			border-bottom: 1px solid $border-color;
			margin-top: 0;
			margin-bottom: -1px;
			
			& .media-object {
				height: rem(48px);
				width: rem(48px);
				line-height: rem(48px);
				font-size: rem(18px);
				color: $white;
				text-align: center;
				
				@include border-radius($border-radius-lg);
			}
			& .media-object-icon {
				position: absolute;
				margin-left: rem(30px);
				border: rem(2px) solid $white;
				background: $white;
				margin-top: rem(-11px);
				display: block;
				width: rem(22px);
				height: rem(22px);
				text-align: center;
				font-size: rem(18px);
				line-height: rem(18px);
				
				@include border-radius($border-radius * 10);
				@if $enable-rtl {
					margin-right: rem(30px);
					margin-left: 0;
				}
			}
			& .media-left {
				padding-right: rem(12px);
				
				@if $enable-rtl {
					padding-right: 0;
					padding-left: rem(12px);
				}
			}
			& .media-right {
				padding-left: rem(12px);
				
				@if $enable-rtl {
					padding-left: 0;
					padding-right: rem(12px);
				}
			}
			& .media-heading {
				color: $dark;
				margin-bottom: 0;
				font-size: rem(14px);
				line-height: rem(20px);
			}
			& p {
				text-overflow: ellipsis;
				overflow: hidden;
				margin-bottom: rem(5px);
				line-height: rem(18px);
				max-width: rem(270px);
				color: lighten($dark, 15%);
				font-size: rem(13px);
			}
		}
		& .dropdown-header {
			font-weight: 500;
			color: $dark;
			font-size: rem(14px);
			padding: rem(12px) rem(16px);
		}
		& .dropdown-item {
			padding: rem(12px) rem(16px);
		}
		& .dropdown-footer { 
			padding: rem(12px) rem(16px);
		}
	}
}