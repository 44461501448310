.footer {
	z-index: $footer-zindex;
	padding: rem(10px) 0;
	margin: 0 $content-padding-x 0 $content-padding-x + $sidebar-width;
	border-top: 1px solid $border-color;
	line-height: rem(20px);
	font-weight: 500;
	color: lighten($dark, 30%);
	font-size: rem(13px);
	
	@if $enable-rtl {
		margin-left: $content-padding-x;
		margin-right: $content-padding-x + $sidebar-width;
	}
	@include media-breakpoint-down(sm) {
		margin-left: $content-padding-x-sm;
		
		@if $enable-rtl {
			margin-right: $content-padding-x-sm;
		}
	}
}